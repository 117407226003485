@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap');

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Josefin Sans', sans-serif;
}