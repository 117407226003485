.bl-app__business {
    position: relative;
    background-image: url('../img/ai-generated-8910166.jpg');
    background-size: cover;
    background-position: center;
}

.bl-outlinedInput {
    width: 100%;
}

/* CustomTextField.css */
.bl-outlinedInput .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #3c586b40 !important;
    padding: 1px !important;
    /* Default outline color */
}

.bl-outlinedInput .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #3c586b40 !important;
    /* Outline color on hover */
}

.bl-outlinedInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #3c586b40 !important;
    /* Outline color when focused */
}

.bl-outlinedInput .MuiOutlinedInput-input {
    padding: 8px;
    /* Padding inside the input */
}

/* .÷ */

.bl-app__business::before {
    content: "";
    /* Needed for the pseudo-element */
    position: absolute;
    /* Position it absolutely */
    top: 0;
    /* Stretch it to the top */
    left: 0;
    /* Stretch it to the left */
    right: 0;
    /* Stretch it to the right */
    bottom: 0;
    /* Stretch it to the bottom */
    background-color: rgba(0, 0, 0, 0.5);
    /* Black with 50% opacity */
    z-index: 1;
    /* Ensure it’s above the background but below the content */
}

.bl-app__wrapper {
    position: relative;
    /* Position the content above the overlay */
    z-index: 2;
    /* Ensure content is above the overlay */
}