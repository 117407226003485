.bl-navbar {
    background-color: #ffffff !important;
    box-shadow: none !important;
}

.bl-navbar__toolbar {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 0 !important;
}

.bl-navbar__logo-container {
    display: flex !important;
    align-items: center !important;
}

.bl-navbar__logo {
    width: 120px;
}

.bl-navbar__menu {
    display: flex !important;
    gap: 4px !important;
}

.bl-navbar__menu-item.active {
    border-radius: 6px;
    /* padding-top: 2px;
    padding-bottom: 2px; */
    border: 1px solid #3c486b;
}

.bl-navbar__menu-item {
    font-size: 16px !important;
    text-transform: capitalize !important;
    color: #3c486b !important;
    text-decoration: none;
    padding: 5px 15px;
}

.bl-navbar__login-btns {
    display: flex;
    gap: 16px;
    /* Spacing between login buttons */
}

.bl-navbar__login-btn {
    color: #3c486b !important;
    border-color: #3c486b !important;
    text-transform: none !important;
    /* Ensure text stays as is */
}

.bl-navbar__login-btn--contained {
    background-color: #3c486b !important;
    color: #ffffff !important;
    /* Set text color to white */
}